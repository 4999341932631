import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getEventsChart} from '../../../features/Events/eventsActions';
import {TabItem, Tabs} from '../../../shared/Tabs';
import Upcoming from './Upcoming';
import Canceled from './Canceled';
import Completed from './Completed';
import Ongoing from './Ongoing';
import styles from './EventsList.module.scss';

const EventsList = () => {
  return (
    <div className={`${styles.list}`}>
      <h3>Events list</h3>
      <Tabs defaultIndex='1'>
        <TabItem label='Upcoming' index='1'>
          <Upcoming />
        </TabItem>
        <TabItem label='Ongoing' index='2'>
          <Ongoing />
        </TabItem>
        <TabItem label='Completed' index='3'>
          <Completed />
        </TabItem>
        <TabItem label='Canceled' index='4'>
          <Canceled />
        </TabItem>
      </Tabs>
    </div>
  );
};

export default EventsList;
