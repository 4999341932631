import React from 'react';
import AuthRoutes from '../../routes/AuthRoutes';

import './AuthContainer.scss';
import Logo from "../../assets/icons/logo_auth.svg";

const AuthContainer = () => {
  return (
    <main className='auth-page'>
      <div className='auth-page__content'>
          <img className='logo' src={Logo} alt="Logo"/>
          <AuthRoutes />
      </div>
    </main>
  );
};

export default AuthContainer;
