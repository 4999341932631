import React from 'react';
import Total from './Total/Total';
import OnMap from './OnMap/OnMap';
import EventsList from './EventsList/EventsList';
import styles from './Events.module.scss';
const Events = () => {
    return (
        <div className={`${styles.events} page-wrap`}>
            <Total/>
            <OnMap/>
            <EventsList/>
        </div>

    );
};

export default Events;
