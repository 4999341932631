import React from 'react';
import Total from './Total/Total';
import Activity from './Activity/Activity';
import UserProfiles from './UserProfiles/UserProfiles';
import styles from './Users.module.scss';


const Users = () => {
  return (
      <div className={`${styles.users} page-wrap`}>
          <Total/>
          <Activity/>
          <UserProfiles/>
      </div>

  );
};

export default Users;
