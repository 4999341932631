import React, {useState, useEffect} from 'react';
import {Pagination} from '../../../shared';
import {ReactComponent as SearchIcon} from '../../../assets/images/search_btn.svg';
import {ReactComponent as SortUpIcon} from '../../../assets/images/sort_up.svg';
import {ReactComponent as SortDownIcon} from '../../../assets/images/sort_down.svg';
import {useDispatch} from 'react-redux';
import {getVenuesList, searchVenues} from '../venuesActions';
import styles from './VenuesList.module.scss';

const Deleted = ({styles, array}) => {
  const [inputField, setInputField] = useState('');
  const [activePage, setActivePage] = useState(0);

  const dispatch = useDispatch();

  const setPage = ({selected}, isReload) => {
    setActivePage(selected);
    setPageParams({...pageParams, page: selected});
    //if (isReload) doRequest(selected);
  };

  const [pageParams, setPageParams] = useState({
    tab: 'deleted',
    search: null,
    page: 1,
    ordering: '' // name -name created -created
  });

  useEffect(() => {
    dispatch(searchVenues(pageParams.tab, pageParams.page, pageParams.search, pageParams.ordering));
  }, [pageParams]);

  const sortList = [
    {name: 'Name', value: 'name', is_sorted: true},
    {name: 'Contact number', value: 'contact_number', is_sorted: false},
    // {name: 'QR-codes used/purchased', value: 'qr_codes', is_sorted: true},
    {name: 'Created', value: 'created', is_sorted: true}
  ];

  return (
    <>
      <div className='search-field mb-32'>
        <input
          className='max-w-360'
          type='search'
          placeholder='Search venues by name'
          onChange={(e) => {
            setPageParams({...pageParams, search: e.target.value, page: 1}), setActivePage(pageParams.page - 1);
          }}
        />
        <SearchIcon />
      </div>

      <table className={`${styles.table} table`}>
        <thead>
          <tr>
            {sortList.map((el, index) => (
              <th key={index}>
                {el.value === null ? (
                  el.name
                ) : (
                  <button
                    className={`good-hover sort_btn${
                      el.value
                        ? el.value ===
                          (pageParams.ordering.includes('-') ? pageParams.ordering.slice(1) : pageParams.ordering)
                          ? el.value === pageParams.ordering
                            ? ' up'
                            : ' down'
                          : ''
                        : ' no-sort'
                    }`}
                    onClick={() =>
                      el.value &&
                      setPageParams({
                        ...pageParams,
                        ordering:
                          el.value ===
                          (pageParams.ordering.includes('-') ? pageParams.ordering.slice(1) : pageParams.ordering)
                            ? el.value === pageParams.ordering
                              ? `-${el.value}`
                              : el.value
                            : el.value
                      })
                    }
                  >
                    {el.name}
                    {el.is_sorted && (
                      <div>
                        <SortUpIcon className='up_icon' />
                        <SortDownIcon className='up_down' />
                      </div>
                    )}
                  </button>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {array && array.results?.length ? (
            array.results?.map(({name, contact_number, qr_used = true, qr_purchased = true, created}, index) => (
              <tr key={index}>
                <td className='w-28' title={name ? name : '-'}>
                  {name ? name : '-'}
                </td>
                <td className='w-26'>{contact_number ? contact_number : '-'}</td>
                {/* <td className='w-22'>
                  {qr_used ? qr_used : '-'}
                  <span> / {qr_purchased ? qr_purchased : '-'}</span>
                </td> */}
                <td className='w-24'>{created ? created : '-'}</td>
              </tr>
            ))
          ) : pageParams.search ? (
            <p className={styles.empty_message}>
              <td className='w-15'>
                <p className={styles.empty_message}>
                  There are no matches{' '}
                  {pageParams.search ? (
                    <>
                      <span> "{pageParams.search}</span>"
                    </>
                  ) : null}
                </p>
              </td>
            </p>
          ) : (
            <p className={styles.empty_message}>There are no deleted venues yet</p>
          )}
        </tbody>
      </table>

      {array.count > 10 && (
        <Pagination
          active={activePage}
          onChange={(page) => {
            setPage(page, true);
            setPageParams({...pageParams, page: page.selected + 1});
          }}
          pageCount={Math.ceil(array.count / 10)}
        />
      )}
    </>
  );
};

export default Deleted;
