import React, {useEffect} from 'react';
import {PieChart} from 'react-minimal-pie-chart';
import styles from './Total.module.scss';
import {getStats} from '../userActions';
import {useDispatch, useSelector} from 'react-redux';
import {PropagateLoader} from 'react-spinners';

const Total = () => {
  const {stats, statsLoad} = useSelector(({user}) => user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStats());
  }, []);

  useEffect(() => {}, [stats]);

  const data = [
    {title: 'Profiles created', value: stats.total_profiles, color: '#ec5b75'},
    {title: 'Anonymous usage', value: stats.total_anonymous, color: '#D0F4B4'},
    {title: 'Accounts deleted', value: stats.total_users, color: '#d1d5d9'}
  ];

  const chartSum = data?.map((item) => item.value).reduce((prev, curr) => prev + curr, 0);

  return (
    <div className={styles.root}>
      <h3>Total</h3>
      {statsLoad ? (
        <div className='load'>
          <PropagateLoader color={'#EC5B75'} />
        </div>
      ) : (
        <div className={styles.inner}>
          <div className={styles.chart_wrap}>
            <PieChart className={styles.chart} data={data} startAngle={-88} lineWidth={15} paddingAngle={5} animate />
            <div className={styles.chart_inner}>
              <b className={styles.chart_sum}>{chartSum}</b>
              <span className={styles.chart_label}>Venues</span>
            </div>
          </div>
          <ul className={styles.list}>
            {data?.map(({title, value, color}, index) => (
              <li key={index} className={styles.item}>
                <span style={{backgroundColor: `${color}`}} /> <b>{value}</b> {title}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Total;
