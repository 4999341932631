import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {useParams, NavLink} from 'react-router-dom';

import {yupResolver} from '@hookform/resolvers/yup';
import {authPath, rootMainPath} from '../../routes/paths';
import {postPassConfirm, postSignIn} from './authActions';
import {useQueryParams, useToggle} from '../../helpers/hooks';
import InputMUI from '../../shared/InputMUI';
import ButtonMUI from '../../shared/ButtonMUI';
import SnackbarMUI from '../../shared/SnackbarMUI/SnackbarMUI';

import {ReactComponent as Tick} from '../../assets/icons/tick.svg';

const PasswordConfirm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const {loading} = useSelector(({app}) => app);
  const {passConfirmError} = useSelector(({auth}) => auth);
  const [token, setToken] = useState({});
  const [buttonLoad, setButtonLoad] = useState(false);

  const deleteQuery = () => {
    queryParams.delete('security_token');
    history.replace({search: queryParams.toString()});
  };

  useEffect(() => {
    if (queryParams.has('security_token')) {
      setToken({security_token: queryParams.get('security_token')});
    } else {
      history.push(authPath.signIn);
    }
  }, []);

  const [sent, setSent] = useState(false);
  const [error, toggleError] = useToggle(false);
  const schema = yup.object({
    password: yup.string().min(8, 'Min 8 characters').required('Field is required'),
    confirm_password: yup
      .string()
      .min(8, 'Min 8 characters')
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Required')
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: {errors, isValid}
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      password: '',
      confirm_password: ''
    }
  });

  const onSubmit = (data) => {
    setButtonLoad(true);
    dispatch(
      postPassConfirm({
        ...data,
        security_token: queryParams.get('security_token')
      })
    )
      .then((res) => {
        if (res.payload && res.payload.status && res.payload.status === 200) {
          setSent(true);
          deleteQuery();
        } else {
          errors.password && setError('password', {type: 'manual', message: errors.password});
          errors.confirm_password && setError('confirm_password', {type: 'manual', message: errors.confirm_password});
          toggleError();
        }
      })
      .then(() => {
        setButtonLoad(false);
      });
  };

  return (
    <form className='auth-box' onSubmit={handleSubmit(onSubmit)}>
      <h1 className='auth-box__title'>Password recovery</h1>
      <p className='auth-box__desc'>
        {!sent
          ? 'Create a new password and confirm it'
          : 'Success! Your password has been changed. Now you can log in.'}
      </p>
      {!sent ? (
        <>
          <div className='auth-box__input_box'>
            <span>New password</span>
            <Controller
              name='password'
              control={control}
              render={({field}) => (
                <InputMUI
                  className='auth-box__input'
                  type='password'
                  fullWidth
                  error={errors.password?.message}
                  inputProps={field}
                  placeholder='Type here...'
                />
              )}
            />
          </div>
          <div className='auth-box__input_box'>
            <span>Confirm password</span>
            <Controller
              name='confirm_password'
              control={control}
              render={({field}) => (
                <InputMUI
                  className='auth-box__input'
                  type='password'
                  fullWidth
                  error={errors.confirm_password?.message}
                  inputProps={field}
                  placeholder='Type here...'
                />
              )}
            />
          </div>
          <ButtonMUI className='auth-box__btn' disabled={!isValid || loading} loading={buttonLoad} formAction>
            Confirm
          </ButtonMUI>
        </>
      ) : (
        <>
          <div className='auth-box__rounded-wrap'>
            <Tick />
          </div>
          <ButtonMUI className='auth-box__btn' component={Link} to={authPath.signIn}>
            Sign in
          </ButtonMUI>
        </>
      )}

      <SnackbarMUI open={error} autoHideDuration={6000} onClose={toggleError} errors={passConfirmError} />
    </form>
  );
};

export default PasswordConfirm;
