import {INTERESTS} from './interestsActionTypes';

export function getInterests(page, search, ordering) {
    return {
        type: INTERESTS.GET_INTERESTS,
        payload: {
            request: {
                url: `/profiles/interests/?${page ? `&page=${page}` : ''}${search ? `&search=${search}` : ''}${
                    ordering ? `&ordering=${ordering}` : ''
                }`,
                method: 'GET'
            }
        }
    };
}