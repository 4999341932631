import {USER} from './userActionTypes';
const summ2Str = (first, second) => eval(first + (second === '-' ? '' : '+') + Number(second))

const INITIAL_STATE = {
    user_profiles: {},
    user_profilesLoad: true,

    profiles: {},
    profilesLoad: true,

    subscriptions_paid: {},
    subscriptions_paidLoad: true,

    subscriptions_qr: {},
    subscriptions_qrLoad: true,

    stats: {},
    statsLoad: true,
    arrayUsers : [],
    arrayPaid : [],
    // arrayQr : [],
    arrayDate : [],
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER.GET_USER_PROFILES_SUCCESS:
            return {
                ...state,
                user_profiles: action.payload.data,
                user_profilesLoad: false
            };
        case USER.GET_PROFILES_SUCCESS:
            return {
                ...state,
                profiles: action.payload.data,
                profilesLoad: false
            };
        case USER.GET_SUBSCRIPTIONS_QR_SUCCESS:
            return {
                ...state,
                subscriptions_qr: action.payload.data,
                subscriptions_qrLoad: false
            };

        case USER.GET_SUBSCRIPTIONS_PAID_SUCCESS:
            return {
                ...state,
                subscriptions_paid: action.payload.data,
                subscriptions_paidLoad: false
            };
        case USER.GET_STATS_SUCCESS:
            let statsChart = action.payload.data.activity.map(el => {
                state.arrayUsers.push(el.users),
                state.arrayPaid.push(el.subs_paid),
                // state.arrayQr.push(el.subs_qr),
                state.arrayDate.push(el.date)
            });
            return {
                ...state,
                stats: action.payload.data,
                statsLoad: false,
            };
        case USER.GET_STATS:
            return {
                ...state,
                arrayUsers: [],
                arrayPaid: [],
                arrayDate: [],
                // arrayQr: [],
            };
        default:
            return state;
    }
}
