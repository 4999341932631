import React, {useEffect, useState, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {mainPath} from '../../../routes/paths';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow_next.svg';
import {ReactComponent as StarIcon} from '../../../assets/icons/star.svg';
import {ReactComponent as LocationIcon} from '../../../assets/icons/location.svg';
import {ReactComponent as CallIcon} from '../../../assets/icons/call.svg';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import styles from './OnMapEvents.module.scss';
import {getCoordinates, getEventDetails} from '../eventsActions';
import {useDispatch, useSelector} from 'react-redux';
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from 'react-google-maps';
import SearchPanel from '../../Venues/OnMapFull/SearchPanel';
import {GOOGLEMAPS_API_KEY} from '../../../config';
import iconTemplate from '../../../assets/images/anonym.png';
import {API_BASE_URL} from '../../../config';

const OnMapEvents = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCoordinates());
  }, []);
  const coordinates = useSelector((state) => state.events.coordinates);
  const singleVenue = useSelector((state) => state.events.singleEvent);
  const [venue, setVenue] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    venue && dispatch(getEventDetails(venue));
  }, [venue]);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({lat, lng}) => {
    mapRef.current.panTo({lat, lng});
    mapRef.current.setZoom(14);
  }, []);

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        onLoad={onMapLoad}
        id='map'
        ref={mapRef}
        defaultZoom={8}
        defaultCenter={{lat: 49.5785958, lng: 34.5053684}}
      >
        {coordinates.map((coordinate) => (
          <Marker
            key={coordinate.id}
            icon={{
              url: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(30, 30)
            }}
            onClick={(e) => {
              setVenue(coordinate.id);
              setOpenPopup(true);
            }}
            position={{lat: coordinate.latitude, lng: coordinate.longitude}}
          ></Marker>
        ))}
      </GoogleMap>
    ))
  );

  return (
    <div className={`${styles.root} page-wrap`}>
      <ul className={styles.breadcrumbs}>
        <li>
          <Link className='good-hover' to={mainPath.events}>
            Venues
          </Link>
        </li>
        <li>
          <ArrowIcon />
          On the map
        </li>
      </ul>

      <div className={styles.section}>
        <div className={styles.map_wrapper}>
          <div className={styles.map_inner}>
            {singleVenue && openPopup && (
              <div className={styles.event}>
                <img
                  className={singleVenue.event_img}
                  src={
                    singleVenue && singleVenue.pictures && singleVenue.pictures.length > 0
                      ? `${API_BASE_URL}${singleVenue.pictures[0]}`
                      : iconTemplate
                  }
                  alt='Event'
                  width='256px'
                  height='192px'
                />
                <div className={styles.event_title}>
                  <b>{singleVenue.name}</b>
                  {/* <span>
                    <StarIcon />
                    3.3
                  </span> */}
                </div>
                {singleVenue.address && (
                  <p className={styles.event_location}>
                    <LocationIcon />
                    {singleVenue.address}
                  </p>
                )}
                {singleVenue.description && <p className={styles.event_desc}>{singleVenue.description}</p>}
                {singleVenue.contact_number && (
                  <a className={`${styles.event_tel} good-hover`} href={`tel:${singleVenue.contact_number}`}>
                    <CallIcon />
                    {singleVenue.contact_number}
                  </a>
                )}
                {/* <Link className={styles.event_link} to={'/'}>
                  View event details
                </Link> */}

                <button
                  onClick={() => setOpenPopup(false)}
                  className={`${styles.event_close} good-hover`}
                  type='button'
                >
                  <CloseIcon />
                </button>
              </div>
            )}
          </div>

          <SearchPanel panTo={panTo} />
          {useMemo(
            () => (
              <MapWithAMarker
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLEMAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{height: `0%`}} />}
                containerElement={<div style={{height: `768px`}} />}
                mapElement={<div style={{height: `100%`}} />}
              />
            ),
            [coordinates]
          )}
        </div>
      </div>
    </div>
  );
};

export default OnMapEvents;
