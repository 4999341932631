import React, {useEffect, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import styles from './OnMap.module.scss';
import 'leaflet/dist/leaflet.css';
import {getSingleVenue} from '../venuesActions';
import {Link} from 'react-router-dom';
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/arrow_right.svg';
import {ReactComponent as LocationIcon} from '../../../assets/icons/location.svg';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close2.svg';
import SearchPanel from '../OnMapFull/SearchPanel';
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from 'react-google-maps';
import {GOOGLEMAPS_API_KEY} from '../../../config';
import iconTemplate from '../../../assets/images/anonym.png';
import {API_BASE_URL} from '../../../config';

function PlacesMap({coordinates}) {
  const [venue, setVenue] = useState(null);
  const [is_popup, setIsPopup] = useState(false);
  const dispatch = useDispatch();
  const singleVenue = useSelector((state) => state.venues.singleVenue);

  useEffect(() => {
    venue && dispatch(getSingleVenue(venue));
  }, [venue]);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({lat, lng}) => {
    mapRef.current.panTo({lat, lng});
    mapRef.current.setZoom(14);
  }, []);

  const showPopup = (venue) => {
    setVenue(venue);
    setIsPopup(true);
  };

  const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        onLoad={onMapLoad}
        id='map'
        ref={mapRef}
        defaultZoom={8}
        defaultCenter={{
          lat: coordinates[0]?.latitude ? coordinates[0]?.latitude : 49.5785958,
          lng: coordinates[0]?.longitude ? coordinates[0]?.longitude : 34.5518679
        }}
      >
        {coordinates.map((coordinate) => (
          <Marker
            key={coordinate.id}
            icon={{
              url: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(30, 30)
            }}
            onClick={(e) => {
              showPopup(coordinate.id);
            }}
            position={{lat: coordinate.latitude, lng: coordinate.longitude}}
          ></Marker>
        ))}
      </GoogleMap>
    ))
  );

  return (
    <div className='map__container'>
      <SearchPanel smallMap panTo={panTo} />
      {useMemo(
        () => (
          <MapWithAMarker
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLEMAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{height: `0%`}} />}
            containerElement={<div style={{height: `340px`}} />}
            mapElement={<div style={{height: `100%`}} />}
          />
        ),
        [coordinates]
      )}

      {singleVenue && is_popup && (
        <div className={styles.event} to='/'>
          <img
            src={
              singleVenue && singleVenue.pictures && singleVenue.pictures.length > 0
                ? `${API_BASE_URL}${singleVenue.pictures[0]}`
                : iconTemplate
            }
            alt='Event'
            width='48px'
            height='48px'
          />

          <div>
            <b>{singleVenue.name}</b>
            <span>
              <LocationIcon />
              {singleVenue?.address}
            </span>
          </div>
          {/* <ArrowRightIcon /> */}
          <button onClick={() => setIsPopup(false)} className={styles.close_btn}>
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  );
}

export default PlacesMap;
