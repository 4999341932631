import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {authPath, rootMainPath} from '../../routes/paths';
import {postPassRecovery, postSignIn} from './authActions';
import {useToggle} from '../../helpers/hooks';
import InputMUI from '../../shared/InputMUI';
import ButtonMUI from '../../shared/ButtonMUI';
import SnackbarMUI from '../../shared/SnackbarMUI';
import {ReactComponent as Sms} from '../../assets/icons/sms_tracking.svg';

const PasswordRecovery = () => {
  const dispatch = useDispatch();
  const {loading} = useSelector(({app}) => app);
  const {passRecoveryError} = useSelector(({auth}) => auth);
  const [sent, setSent] = useState(false);
  const [error, toggleError] = useToggle(false);
  const [buttonLoad, setButtonLoad] = useState(false);

  const schema = yup.object({
    email: yup.string().required('Required').email('Incorrect email')
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: {errors, isValid}
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  });

  const onSubmit = (data) => {
    setButtonLoad(true);
    dispatch(postPassRecovery(data))
        .then((res) => {
          if (res.payload && res.payload.status && res.payload.status === 200) {
            setSent(true);
          } else {
            errors.email && setError('email', {type: 'manual', message: errors.email});
            toggleError();
          }
        })
        .then(() => {
          setButtonLoad(false);
        });
  };

  return (
    <form className='auth-box' onSubmit={handleSubmit(onSubmit)}>
      <h1 className='auth-box__title'>Password recovery</h1>
      <p className='auth-box__desc'>
        {!sent ?
            'Enter the email address you used to register to reset your password'
            :
            'We sent you an email with the link to reset your password'
        }
      </p>
      {!sent ? (
        <>
          <div className='auth-box__input_box'>
            <span>Email</span>
            <Controller
                name='email'
                control={control}
                render={({field}) => (
                    <InputMUI
                        className='auth-box__input'
                        type='email'
                        fullWidth
                        error={errors.email?.message}
                        inputProps={field}
                        placeholder='Type here...'
                    />
                )}
            />
          </div>
          <ButtonMUI className='auth-box__btn' disabled={!isValid || loading} loading={buttonLoad} formAction>
            Continue
          </ButtonMUI>
        </>
      ) : (
        <div className='auth-box__rounded-wrap'>
          <Sms/>
        </div>
      )}
      <Link className='backlink' to={authPath.signIn}>Back to Sign in</Link>
      <SnackbarMUI open={error} autoHideDuration={6000} onClose={toggleError} errors={passRecoveryError} />
    </form>
  );
};

export default PasswordRecovery;
