import React from 'react';
import {Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption} from '@reach/combobox';

import usePlacesAutocomplete, {getGeocode, getLatLng} from 'use-places-autocomplete';
import styles from './OnMapFull.module.scss';
import {ReactComponent as Search} from '../../../assets/icons/find.svg';

function SearchPanel({panTo, smallMap}) {
  const {
    ready,
    value,
    suggestions: {status, data},
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {lat: () => 43.6532, lng: () => -79.3832},
      radius: 100 * 1000
    }
  });

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({address});
      const {lat, lng} = await getLatLng(results[0]);
      panTo({lat, lng});
    } catch (error) {
      console.log('😱 Error: ', error);
    }
  };

  return (
    <div className={smallMap ? styles.search__block_s : styles.search__block}>
      <Combobox onSelect={handleSelect}>
        <Search />
        <ComboboxInput value={value} onChange={handleInput} disabled={!ready} placeholder='Search on the map' />
        <ComboboxPopover className={smallMap ? styles.search__container_s : styles.search__container}>
          <ComboboxList>
            {status === 'OK' && data.map(({id, description}) => <ComboboxOption key={id} value={description} />)}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

export default SearchPanel;
