import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {authPath, mainPath, rootMainPath} from '../../routes/paths';
import {postSignIn} from './authActions';
import {useToggle} from '../../helpers/hooks';
import InputMUI from '../../shared/InputMUI';
import ButtonMUI from '../../shared/ButtonMUI';
import SnackbarMUI from '../../shared/SnackbarMUI';

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {buttonLoading} = useSelector(({app}) => app);
  const {signInError} = useSelector(({auth}) => auth);
  const [buttonLoad, setButtonLoad] = useState(false);

  const [error, toggleError] = useToggle(false);

  useEffect(() => {
    localStorage.getItem('token') && history.push(rootMainPath);
  }, []);

  const schema = yup.object({
    email: yup.string().email('Enter a valid email').required('Field is required'),
    password: yup.string().min(8, 'Min 8 characters').required('Field is required')
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: {errors, isValid}
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = (data) => {
    setButtonLoad(true);
    dispatch(postSignIn(data))
      .then((res) => {
        if (res.payload && res.payload.status && res.payload.status === 200) {
          localStorage.setItem('token', res.payload.data.token);

          history.push(rootMainPath);
        } else {
          errors.email && setError('email', {type: 'manual', message: errors.email});
          errors.password && setError('password', {type: 'manual', message: errors.password});
          toggleError();
        }
      })
      .then(() => {
        setButtonLoad(false);
      });
  };

  return (
    <form className='auth-box' onSubmit={handleSubmit(onSubmit)}>
      <h1 className='auth-box__title'>Sign in</h1>
      <p className='auth-box__desc'>Fill the fields below to log in to your account</p>
 

      <div className='auth-box__input_box'>
        <span>Email</span>
        <Controller
          name='email'
          control={control}
          render={({field}) => (
            <InputMUI
              className='auth-box__input'
              type='email'
              fullWidth
              error={errors.email?.message}
              inputProps={field}
              placeholder='Type here...'
            />
          )}
        />
      </div>
      <div className='auth-box__input_box'>
        <span>Password</span>
        <p>
          <Link className='pulse' to={authPath.passRecovery}>
            Forgot password?
          </Link>
        </p>
        <Controller
          name='password'
          control={control}
          render={({field}) => (
            <InputMUI
              className='auth-box__input'
              type='password'
              fullWidth
              error={errors.password?.message}
              inputProps={field}
              placeholder='Type here...'
            />
          )}
        />
      </div>
      <ButtonMUI className='auth-box__btn' disabled={!isValid || buttonLoading} loading={buttonLoad} formAction>
        Sign in
      </ButtonMUI>
      <SnackbarMUI open={error} autoHideDuration={6000} onClose={toggleError} errors={signInError} />
    </form>
  );
};

export default SignIn;
