import {USER} from './userActionTypes';

export function getUserProfiles(page, search, ordering) {
    return {
        type: USER.GET_USER_PROFILES,
        payload: {
            request: {
                url: `/profiles/?${page ? `&page=${page}` : ''}${search ? `&search=${search}` : ''}${
                    ordering ? `&ordering=${ordering}` : ''
                }`,
                method: 'GET'
            }
        }
    };
}

export function getProfiles(id) {
    return {
        type: USER.GET_PROFILES,
        payload: {
            request: {
                url: `/profiles/${id}/`,
                method: 'GET'
            }
        }
    };
}

export function getSubscriptionsPaid( id, url ) {
    return {
        type: USER.GET_SUBSCRIPTIONS_PAID,
        payload: {
            request: {
                url: `/subscriptions/paid/${id}/${url && url.length > 0 ? `?${url}` : ''}`,
                method: 'GET',
            }
        }
    };
}

export function getSubscriptionsQR(id, url) {
    return {
        type: USER.GET_SUBSCRIPTIONS_QR,
        payload: {
            request: {
                url: `/subscriptions/qr/${id}/${url && url.length > 0 ? `?${url}` : ''}`,
                method: 'GET',
            }
        }
    };
}

export function getStats() {
    return {
        type: USER.GET_STATS,
        payload: {
            request: {
                url: `/profiles/stats/`,
                method: 'GET'
            }
        }
    };
}

