export const USER = {
    GET_USER_PROFILES: 'GET_USER_PROFILES',
    GET_USER_PROFILES_SUCCESS: 'GET_USER_PROFILES_SUCCESS',
    GET_USER_PROFILES_FAIL: 'GET_USER_PROFILES_FAIL',

    GET_PROFILES: 'GET_PROFILES',
    GET_PROFILES_SUCCESS: 'GET_PROFILES_SUCCESS',
    GET_PROFILES_FAIL: 'GET_PROFILES_FAIL',

    GET_SUBSCRIPTIONS_PAID: 'GET_SUBSCRIPTIONS_PAID',
    GET_SUBSCRIPTIONS_PAID_SUCCESS: 'GET_SUBSCRIPTIONS_PAID_SUCCESS',
    GET_SUBSCRIPTIONS_PAID_FAIL: 'GET_SUBSCRIPTIONS_PAID_FAIL',


    GET_SUBSCRIPTIONS_QR: 'GET_SUBSCRIPTIONS_QR',
    GET_SUBSCRIPTIONS_QR_SUCCESS: 'GET_SUBSCRIPTIONS_QR_SUCCESS',
    GGET_SUBSCRIPTIONS_QR_FAIL: 'GGET_SUBSCRIPTIONS_QR_FAIL',

    GET_STATS: 'GET_STATS',
    GET_STATS_SUCCESS: 'GET_STATS_SUCCESS',
    GET_STATS_FAIL: 'GET_STATS_FAIL',
};
