import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Examples from '../features/Examples';
import Dashboard from '../features/Dashboard';
import Users from '../features/Users';
import UserDetails from '../features/Users/UserDetails/UserDetails';
import Events from '../features/Events';
import {rootMainPath, rootAuthPath, mainPath} from './paths';
import NotFound from '../shared/NotFound';
import Venues from '../features/Venues';
import OnMapFull from '../features/Venues/OnMapFull';
import Interests from '../features/Interests';
import OnMapEvents from "../features/Events/OnMapEvents/OnMapEvents";


const MainRoutes = () => {
  if (!localStorage.token) return <Redirect to={rootAuthPath} />;

  return (
    <Switch>
      <Redirect from={rootMainPath} exact to={mainPath.users} />
      <Route path={mainPath.dashboard} component={Dashboard} />
      <Route path={mainPath.users} component={Users} />
      <Route path={mainPath.usersDetails} component={UserDetails} />
      <Route path={mainPath.events} component={Events} />
        <Route path={mainPath.eventsMap} component={OnMapEvents} />
        <Route path={mainPath.venues} exact component={Venues} />
      <Route path={mainPath.venuesMap} component={OnMapFull} />
      <Route path={mainPath.interests} component={Interests} />
      <Route path={mainPath.examples} component={Examples} />
      <Route path='*' component={NotFound} />
    </Switch>
  );
};

export default MainRoutes;
