import React, {useEffect} from 'react';
import {PieChart} from 'react-minimal-pie-chart';
import styles from './Total.module.scss';
import {useSelector, useDispatch} from 'react-redux';
import {getEventsChart} from '../../../features/Events/eventsActions';

const Total = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEventsChart());
  }, []);

  const chart = useSelector((state) => state.events.chartData);

  const data = [
    {title: 'Upcoming', value: chart && chart.upcoming && chart.upcoming, color: '#FFD484'},
    {title: 'Ongoing', value: chart && chart.ongoing && chart.ongoing, color: '#ec5b75'},
    {title: 'Completed', value: chart && chart.completed && chart.completed, color: '#D0F4B4'},
    {title: 'Canceled', value: chart && chart.canceled && chart.canceled, color: '#d1d5d9'}
  ];

  const chartSum = data?.map((item) => item.value).reduce((prev, curr) => prev + curr, 0);

  return (
    <div className={styles.root}>
      <h3>Total</h3>
      <div className={styles.inner}>
        <div className={styles.chart_wrap}>
          <PieChart className={styles.chart} data={data} startAngle={-88} lineWidth={15} paddingAngle={5} animate />
          <div className={styles.chart_inner}>
            <b className={styles.chart_sum}>{chartSum}</b>
            <span className={styles.chart_label}>Venues</span>
          </div>
        </div>
        <ul className={styles.list}>
          {data?.map(({title, value, color}, index) => (
            <li key={index} className={styles.item}>
              <span style={{backgroundColor: `${color}`}} /> <b>{value}</b> {title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Total;
