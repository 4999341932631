import React, {useState, useEffect} from 'react';
import styles from './EventsList.module.scss';
import {ReactComponent as Search} from '../../../assets/images/search_btn.svg';
import {ReactComponent as SortUpIcon} from '../../../assets/images/sort_up.svg';
import {ReactComponent as SortDownIcon} from '../../../assets/images/sort_down.svg';
import {Pagination} from '../../../shared';
import {useDispatch, useSelector} from 'react-redux';
import {searchEvents} from '../eventsActions';
import moment from 'moment';

const Completed = () => {
  const [activePage, setActivePage] = useState(0);

  const dispatch = useDispatch();

  const array = useSelector((state) => state.events.events.results);
  const count = useSelector((state) => state.events.events.count);
  const state = useSelector((state) => state.events.events);

  const setPage = ({selected}, isReload) => {
    setActivePage(selected);
    setPageParams({...pageParams, page: selected});
  };

  const sortList = [
    {
      name: 'Name',
      value: 'name',
      is_sorted: true
    },
    {
      name: 'Venue',
      value: 'venue__name',
      is_sorted: true
    },
    {
      name: 'Interests',
      value: 'symbol__interests',
      is_sorted: false
    },
    {
      name: 'Interested people',
      value: 'interesed_people',
      is_sorted: true
    },
    // {
    //   name: 'QR-codes purchased',
    //   value: 'symbol__qr_codes',
    //   is_sorted: true
    // },
    {
      name: 'Started',
      value: 'start_date',
      is_sorted: true
    },
    {
      name: 'Completed',
      value: 'end_date',
      is_sorted: false
    }
  ];

  const [pageParams, setPageParams] = useState({
    tab: 'completed',
    search: null,
    page: '',
    ordering: '' // name -name created -created
  });

  useEffect(() => {
    dispatch(searchEvents(pageParams.tab, pageParams.page, pageParams.search, pageParams.ordering));
  }, [pageParams]);

  return (
    <div className={`${styles.list_block}`}>
      <div className={`${styles.filter_search}`}>
        <input
          className='filter_search__input'
          type='text'
          onChange={(e) => setPageParams({...pageParams, search: e.target.value})}
          placeholder='Search events by name'
        />
        <span className='pulse'>
          <Search />
        </span>
      </div>
      <table className={`${styles.table_list} table`}>
        <thead>
          <tr>
            {sortList.map((el, idx) => (
              <th>
                {el.value === null ? (
                  el.name
                ) : (
                  <button
                    key={idx}
                    className={`good-hover sort_btn${
                      el.value
                        ? el.value ===
                          (pageParams.ordering.includes('-') ? pageParams.ordering.slice(1) : pageParams.ordering)
                          ? el.value === pageParams.ordering
                            ? ' up'
                            : ' down'
                          : ''
                        : ' no-sort'
                    }`}
                    disabled={!el.is_sorted}
                    onClick={() =>
                      el.value &&
                      setPageParams({
                        ...pageParams,
                        ordering:
                          el.value ===
                          (pageParams.ordering.includes('-') ? pageParams.ordering.slice(1) : pageParams.ordering)
                            ? el.value === pageParams.ordering
                              ? `-${el.value}`
                              : el.value
                            : el.value
                      })
                    }
                  >
                    {el.name}

                    {el.is_sorted && (
                      <div>
                        <SortUpIcon className='up_icon' />
                        <SortDownIcon className='up_down' />
                      </div>
                    )}
                  </button>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {array && array.length > 0 ? (
            array.map(
              (
                {name, venue, qr_code_used, qr_code_purchased, interesed_people, interest, start_date, end_date},
                idx
              ) => (
                <tr key={idx}>
                  <td className='w-15' title={name}>
                    {name}
                  </td>
                  <td className='w-15'>{venue.name}</td>
                  <td className='w-25'>{interest && interest.map((el, idx) => <div>{el.name}</div>)}</td>
                  <td className='w-15'>{interesed_people}</td>

                  {/* <td className='w-15'>
                  {qr_code_used} <span>/ {qr_code_purchased}</span>{' '}
                </td> */}
                  <td className='w-15'> {moment(start_date).format('DD.MM.yyyy HH:MM')}</td>
                  <td className='w-15'> {moment(end_date).format('DD.MM.yyyy HH:MM')}</td>
                </tr>
              )
            )
          ) : pageParams.search ? (
            <td className='w-15'>
              <p className={styles.empty_message}>
                There are no matches{' '}
                {pageParams.search ? (
                  <>
                    <span> "{pageParams.search}</span>"
                  </>
                ) : null}
              </p>
            </td>
          ) : (
            <p className={styles.empty_message}>There are no completed events yet</p>
          )}
        </tbody>
      </table>
      {count > 10 && (
        <Pagination
          active={activePage}
          onChange={(page) => {
            setPage(page, true);
            setPageParams({...pageParams, page: page.selected + 1});
          }}
          pageCount={Math.ceil(count / 10)}
        />
      )}
    </div>
  );
};

export default Completed;
