let BASE_URL, SECOND_URL;

const host = window.location.host;

if (host.includes('localhost') || host === "admin.cherry.4-com.pro") {
  BASE_URL = "https://api.cherry.4-com.pro";
} else {
  BASE_URL = "https://api.cherrybot.app";
}

SECOND_URL = '';

export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
export const GOOGLEMAPS_API_KEY = 'AIzaSyB-muOIQoIRKh623o63yCbnsPplVtjRGuA';
