import React from 'react';
import {NavLink} from 'react-router-dom';
import {mainPath} from '../../routes/paths';
import {ReactComponent as Calendar} from '../../assets/icons/sidebar/calendar.svg';
import {ReactComponent as Location} from '../../assets/icons/sidebar/location.svg';
import {ReactComponent as User} from '../../assets/icons/sidebar/user.svg';
import {ReactComponent as Star} from '../../assets/icons/sidebar/star.svg';
import {useLocation} from 'react-router-dom';

import './SideBg.scss';

const SideBg = () => {
  const {pathname} = useLocation();

  return (
    <div className='side-bg'>
      <NavLink className='header__nav-link' to={mainPath.users}>
        <User />
        Users
      </NavLink>
      <NavLink className={`header__nav-link ${pathname === '/main/events-map' && 'active'}`} to={mainPath.events}>
        <Calendar />
        Events
      </NavLink>
      <NavLink className={`header__nav-link ${pathname === '/main/venues-map' && 'active'}`} to={mainPath.venues}>
        <Location />
        Venues
      </NavLink>
      <NavLink className='header__nav-link' to={mainPath.interests}>
        <Star />
        Interests
      </NavLink>
    </div>
  );
};

export default SideBg;
