import React, {useEffect, useState} from 'react';
import {Pagination} from '../../shared';
import {useDispatch, useSelector} from "react-redux";
import {getInterests} from './interestsActions';
import {PropagateLoader} from "react-spinners";
import {ReactComponent as Search} from '../../assets/images/search_btn.svg';
import {ReactComponent as SortUpIcon} from '../../assets/images/sort_up.svg';
import {ReactComponent as SortDownIcon} from '../../assets/images/sort_down.svg';
import styles from './Interests.module.scss';

const Interests = () => {
    const { interests, interestsLoad } = useSelector(({ interests }) => (interests));

    const [activePage, setActivePage] = useState(0);

    const dispatch = useDispatch();

    const setPage = ({selected}, isReload) => {
        setActivePage(selected);
        setParameters({...parameters, page: selected});
    };

    const [parameters, setParameters] = useState({
        ordering: '',
        search: null,
        page: 1,
    });

    useEffect(() => {
        dispatch(getInterests( parameters.page, parameters.search, parameters.ordering));
    }, [parameters]);

    const sortList = [
        {name: 'Name', value: 'name'},
        {name: 'Used in profile', value: 'count_profiles'},
        {name: 'Used in search', value: 'count_searches'},
        {name: 'Chats started', value: 'count_chats'},
    ];

    return (
        <div className={`${styles.root} page-wrap`}>
            <div className={styles.section}>
                <h3 className='mb-24'>
                    Interests <span className={styles.quantity}>{interests.count}</span>
                </h3>
                {interestsLoad ?
                    <div className='load'>
                        <PropagateLoader color={'#EC5B75'}/>
                    </div>
                    :
                    <div className='no_load'>
                        <div className={`${styles.filter_search}`}>
                            <input
                                className='filter_search__input'
                                type='text'
                                placeholder='Search interests by name'
                                onChange={(e) => {
                                    setParameters({...parameters, search: e.target.value, page: 1}), setActivePage(parameters.page - 1);
                                }}
                            />
                            <span className='pulse'><Search/></span>
                        </div>
                        <table className={`${styles.table} table`}>
                            <thead>
                            <tr>
                                {sortList.map((el, index) => (
                                    <th key={index}>
                                        {el.value === null ? (
                                            el.name
                                        ) : (
                                            <button
                                                className={`good-hover sort_btn${
                                                    el.value
                                                        ? el.value ===
                                                        (parameters.ordering.includes('-') ? parameters.ordering.slice(1) : parameters.ordering)
                                                        ? el.value === parameters.ordering
                                                            ? ' up'
                                                            : ' down'
                                                        : ''
                                                        : ' no-sort'
                                                }`}
                                                onClick={() =>
                                                    el.value &&
                                                    setParameters({
                                                        ...parameters,
                                                        ordering:
                                                            el.value ===
                                                            (parameters.ordering.includes('-') ? parameters.ordering.slice(1) : parameters.ordering)
                                                                ? el.value === parameters.ordering
                                                                ? `-${el.value}`
                                                                : el.value
                                                                : el.value
                                                    })
                                                }
                                            >
                                                {el.name}
                                                <div>
                                                    <SortUpIcon className='up_icon'/>
                                                    <SortDownIcon className='up_down'/>
                                                </div>
                                            </button>
                                        )}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {interests.count > 0 ? (
                                interests && interests.results && interests.results.map(({name, count_searches, count_chats, count_profiles, matches}, index) => (
                                    <tr key={index}>
                                        <td className='w-25'>
                                            <span className={styles.interest}>{name ? name : '-'}</span>
                                        </td>
                                        <td className='w-25'>
                                            {count_profiles ? <div>{count_profiles}  <span>time(s)</span></div> : '-'}
                                        </td>
                                        <td className='w-25'>
                                            {count_searches ? <div>{count_searches}  <span>time(s)</span></div> : '-'}
                                        </td>
                                        <td className='w-25'>
                                            {count_chats ? <div>{count_chats}  <span>time(s)</span></div> : '-'}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <p className={styles.empty_message}>There are no name {parameters.search ? <span> "{parameters.search} "</span> : null}</p>
                            )}
                            </tbody>
                        </table>
                    </div>
                }
                {interests.count > 10 &&
                    <Pagination
                        active={parameters.page - 1}
                        pageCount={Math.ceil(interests.count / 10)}
                        onChange={(page) => {
                            setPage(page, true);
                            setParameters({...parameters, page: page.selected + 1});
                        }}
                    />
                }
            </div>
        </div>
    );
};

export default Interests;
