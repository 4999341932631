import {AUTH} from './authActionTypes';
import {USER} from "../Users/userActionTypes";

const INITIAL_STATE = {
  signInError: {},
  passRecoveryError: {},
  passConfirmError: {},
  accounts: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH.SIGN_IN_FAIL:
      return {...state, signInError: action.error.response.data};
    case AUTH.PASS_RECOVERY_FAIL:
      return {...state, passRecoveryError: action.error.response.data};
    case AUTH.PASS_CONFIRM_FAIL:
      return {...state, passConfirmError: action.error.response.data};
    case AUTH.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.payload.data,
      };
    default:
      return state;
  }
}
