import React from 'react';
import Header from '../../layout/Header';
import SideBg from '../../layout/SideBg';
import MainRoutes from '../../routes/MainRoutes';

const MainContainer = () => {
    return (
        <>
            <Header />
            <div className='page_wrapper'>
                <SideBg/>
                <MainRoutes />
            </div>
        </>
    );
};

export default MainContainer;
