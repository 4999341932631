import React, { useEffect, useState } from 'react';
import { getUserProfiles } from '../../Users/userActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {Pagination} from "../../../shared";
import {Link} from "react-router-dom";
import {PropagateLoader} from "react-spinners";
import {ReactComponent as Search} from '../../../assets/images/search_btn.svg';
import {ReactComponent as SortDownIcon} from '../../../assets/images/sort_down.svg';
import {ReactComponent as SortUpIcon} from '../../../assets/images/sort_up.svg';
import {ReactComponent as Anonymous} from '../../../assets/images/Anonymous.svg';

import styles from './UserProfiles.module.scss';

const UserProfiles = () => {
    const { user_profiles, user_profilesLoad } = useSelector(({ user }) => (user));

    const [activePage, setActivePage] = useState(0);

    const dispatch = useDispatch();

    const setPage = ({selected}, isReload) => {
        setActivePage(selected);
        setParameters({...parameters, page: selected});
    };

    const now = moment();

    const [parameters, setParameters] = useState({
        ordering: '',
        search: null,
        page: 1,
    });

    useEffect(() => {
        dispatch(getUserProfiles( parameters.page, parameters.search, parameters.ordering));
    }, [parameters]);

    const sortList = [
        {
            name: 'Name',
            value: 'first_name',
        },
        {
            name: 'City',
            value: 'city',
        },
        {
            name: 'Age',
            value: 'birth_date',
        },
        {
            name: 'Interests',
            value: null,
        },
        {
            name: 'Paid subs',
            value: 'subscription_paid',
        },
        // {
        //     name: 'QR-code subs',
        //     value: 'subscription_qr',
        // },
        {
            name: 'Member since',
            value: 'user__registered',
            classes: ''
        },
    ];

    return (
        <div className={`${styles.user_profiles}`}>
            <h3>User profiles</h3>
            {user_profilesLoad ?
                <div className='load'>
                    <PropagateLoader color={'#EC5B75'}/>
                </div>
                :
                <div className='no_load'>
                    <div className={`${styles.filter_search}`}>
                        <input
                            className='filter_search__input'
                            type='text'
                            placeholder='Search users by name or city'
                            onChange={(e) => {
                                setParameters({...parameters, search: e.target.value, page: 1}), setActivePage(parameters.page - 1);
                            }}                        />
                        <span className='pulse'><Search/></span>
                    </div>
                    <table className={`${styles.table_users} table`}>
                        <thead>
                            <tr>
                            {sortList.map((el, idx) => (
                                <th>
                                    {el.value === null ? (
                                        el.name
                                    ) : (
                                        <button
                                            key={idx}
                                            className={`good-hover sort_btn${el.value
                                                ? el.value ===
                                                (parameters.ordering.includes('-') ? parameters.ordering.slice(1) : parameters.ordering)
                                                    ? el.value === parameters.ordering
                                                        ? ' up'
                                                        : ' down'
                                                    : ''
                                                : ' no-sort'
                                            }`}
                                            onClick={() =>
                                                el.value &&
                                                setParameters({
                                                    ...parameters,
                                                    ordering:
                                                        el.value ===
                                                        (parameters.ordering.includes('-') ? parameters.ordering.slice(1) : parameters.ordering)
                                                            ? el.value === parameters.ordering
                                                            ? `-${el.value}`
                                                            : el.value
                                                            : el.value
                                                })
                                            }
                                        >
                                            {el.name}
                                            <div>
                                                <SortUpIcon className='up_icon'/>
                                                <SortDownIcon className='up_down'/>
                                            </div>
                                        </button>
                                    )}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {user_profiles.count > 0 ? (
                            user_profiles && user_profiles.results && user_profiles.results.map(({id, interest, subscription_paid, registered, subscription_qr, birth_date, city, picture, first_name}) => (
                                <tr key={id}>
                                    <td>
                                        <Link className='pulse' to={`/main/user-details/${id}`}>
                                            { picture === null ?<span> <Anonymous/></span> : <span><img src={picture} alt=""/></span>}
                                            { first_name === null ? 'Anonymous' : first_name}
                                            {/*{status ? <OK/> : null}*/}
                                        </Link>
                                    </td>
                                    <td>{city ? city : '-'}</td>
                                    <td>{birth_date ?
                                        <div>{moment(now).format('YYYY') - moment(birth_date).format('YYYY')} y.o.</div> : '-'}</td>
                                    <td>
                                        {interest.length > 3 ?
                                            <span>
                                    {interest.slice(0, 3).map(({id, name}) => (
                                        <span key={id}>
                                            {name}
                                        </span>
                                    ))}
                                                +1
                                </span>
                                            :
                                            <span>
                                    {interest.slice(0, 3).map(({id, name}) => (
                                        <span key={id}>
                                            {name}
                                        </span>
                                    ))}
                                </span>
                                        }
                                    </td>
                                    <td>{subscription_paid === 0 ? '-' : subscription_paid}</td>
                                    {/*<td>{subscription_qr}</td>*/}
                                    <td>{registered ? moment(registered).format('DD.MM.YYYY') : '-'}</td>
                                </tr>
                            ))
                        ) : (
                            <p className={styles.empty_message}>There are no name or city {parameters.search ? <span> "{parameters.search}"</span> : null}</p>
                        )}
                        </tbody>
                    </table>
                    {user_profiles.count > 10 &&
                    <Pagination
                        active={parameters.page - 1}
                        pageCount={Math.ceil(user_profiles.count / 10)}
                        onChange={(page) => {
                            setPage(page, true);
                            setParameters({...parameters, page: page.selected + 1});
                        }}
                    />
                    }
                </div>
            }
        </div>
    );
};

export default UserProfiles;
