import {INTERESTS} from './interestsActionTypes';

const INITIAL_STATE = {
    interests: {},
    interestsLoad: true,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case INTERESTS.GET_INTERESTS_SUCCESS:
            return {
                ...state,
                interests: action.payload.data,
                interestsLoad: false
            };
        default:
            return state;
    }
}
