import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TabItem, Tabs} from '../../../shared';
import Active from './Active';
import Deleted from './Deleted';
import styles from './VenuesList.module.scss';

const VenuesList = () => {
  const {list} = useSelector((state) => state.venues);

  return (
    <div className={styles.root}>
      <h3 className='mb-44'>Venues list</h3>
      <Tabs>
        <TabItem label='Active' index='0'>
          <Active styles={styles} array={list} />
        </TabItem>
        <TabItem label='Deleted' index='1'>
          <Deleted styles={styles} array={list} />
        </TabItem>
      </Tabs>
    </div>
  );
};

export default VenuesList;
