import {EVENTS} from './eventsActionTypes';

export function getEventsChart() {
  return {
    type: EVENTS.GET_EVENTS,
    payload: {
      request: {
        url: `/creator/events/stats/`,
        method: 'GET'
      }
    }
  };
}

export function searchEvents(status, page, search, ordering) {
  return {
    type: EVENTS.SEARCH_EVENTS,
    payload: {
      request: {
        url: `/creator/events/?status=${status}${page ? `&page=${page}` : ''}${search ? `&search=${search}` : ''}${
          ordering ? `&ordering=${ordering}` : ''
        }`,
        method: 'GET'
      }
    }
  };
}

export function getCoordinates() {
  return {
    type: EVENTS.GET_COORDINATES,
    payload: {
      request: {
        url: `/creator/events/map/`,
        method: 'GET'
      }
    }
  };
}

export function getEventDetails(id) {
  return {
    type: EVENTS.GET_EVENT_DETAILS,
    payload: {
      request: {
        url: `/creator/events/${id}/`,
        method: 'GET'
      }
    }
  };
}
