import {EVENTS} from './eventsActionTypes';

const INITIAL_STATE = {
  loading: false,
  chartData: null,
  events: [],
  coordinates: [],
  singleEvent: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case EVENTS.GET_EVENTS:
    case EVENTS.SEARCH_EVENTS:
    case EVENTS.GET_COORDINATES:
    case EVENTS.GET_EVENT_DETAILS:
      return {
        ...state,
        loading: true
      };

    case EVENTS.GET_EVENTS_FAIL:
    case EVENTS.SEARCH_EVENTS_FAIL:
    case EVENTS.GET_COORDINATES_FAIL:
    case EVENTS.GET_EVENT_DETAILS_FAIL:
      return {
        ...state,
        loading: false
      };

    case EVENTS.GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        chartData: action.payload.data
      };

    case EVENTS.SEARCH_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.payload.data
      };

    case EVENTS.GET_COORDINATES_SUCCESS:
      return {
        ...state,
        loading: false,
        coordinates: action.payload.data
      };

    case EVENTS.GET_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        singleEvent: action.payload.data
      };

    default:
      return state;
  }
}
