import React from 'react';
import Total from './Total';
import OnMap from './OnMap';
import VenuesList from './VenuesList';
import styles from './Venues.module.scss';

const Venues = () => {
  return (
    <div className={`${styles.root} page-wrap`}>
      <Total />
      <OnMap />
      <VenuesList />
    </div>
  );
};

export default Venues;
