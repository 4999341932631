import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {mainPath} from '../../../routes/paths';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow_next.svg';
import {ReactComponent as SearchIcon} from '../../../assets/images/search_btn.svg';
import {ReactComponent as LocationIcon} from '../../../assets/icons/location.svg';
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/arrow_right.svg';
import eventImg from '../../../assets/images/event.png';
import styles from './OnMap.module.scss';
import {getCoordinates} from '../venuesActions';
import PlacesMap from './PlacesMap';

const OnMap = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCoordinates());
  }, []);
  const coordinates = useSelector((state) => state.venues.coordinates);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h3>On the map </h3>
        <Link className={`${styles.link} good-hover`} to={mainPath.venuesMap}>
          Full view
          <ArrowIcon />
        </Link>
      </div>
      <div className={styles.map_wrapper}>
        <PlacesMap coordinates={coordinates} />
      </div>
    </div>
  );
};

export default OnMap;
