export const VENUES = {
  // GET STATISTICS FOR CHART
  GET_VENUES: 'GET_VENUES',
  GET_VENUES_SUCCESS: 'GET_VENUES_SUCCESS',
  GET_VENUES_FAIL: 'GET_VENUES_FAIL',

  // GET LIST OF VENUES
  GET_VENUES_LIST: 'GET_VENUES_LIST',
  GET_VENUES_LIST_SUCCESS: 'GET_VENUES_LIST_SUCCESS',
  GET_VENUES_LIST_FAIL: 'GET_VENUES_LIST_FAIL',

  //SEACRH VENUES
  SEARCH_VENUES: 'SEARCH_VENUES',
  SEARCH_VENUES_SUCCESS: 'SEARCH_VENUES_SUCCESS',
  SEARCH_VENUES_FAIL: 'SEARCH_VENUES_FAIL',

  //GET COORDINATES
  GET_COORDINATES: 'GET_COORDINATES',
  GET_COORDINATES_SUCCESS: 'GET_COORDINATES_SUCCESS',
  GET_COORDINATES_FAIL: 'GET_COORDINATES_FAIL',

  //GET SINGLE VENUE
  GET_SINGLE_VENUE: 'GET_SINGLE_VENUE',
  GET_SINGLE_VENUE_SUCCESS: 'GET_SINGLE_VENUE_SUCCESS',
  GET_SINGLE_VENUE_FAIL: 'GET_SINGLE_VENUE_FAIL'
};
