export const AUTH = {
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAIL: 'SIGN_IN_FAIL',

  PASS_RECOVERY: 'PASS_RECOVERY',
  PASS_RECOVERY_SUCCESS: 'PASS_RECOVERY_SUCCESS',
  PASS_RECOVERY_FAIL: 'PASS_RECOVERY_FAIL',

  PASS_CONFIRM: 'PASS_CONFIRM',
  PASS_CONFIRM_SUCCESS: 'PASS_CONFIRM_SUCCESS',
  PASS_CONFIRM_FAIL: 'PASS_CONFIRM_FAIL',

  GET_ACCOUNTS: 'GET_ACCOUNTS',
  GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAIL: 'GET_ACCOUNTS_FAIL'
};
