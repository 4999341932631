export const EVENTS = {
  // GET STATISTICS FOR CHART
  GET_EVENTS: 'GET_EVENTS',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_FAIL: 'GET_EVENTS_FAIL',

  // SEARCH EVENTS
  SEARCH_EVENTS: 'SEARCH_EVENTS',
  SEARCH_EVENTS_SUCCESS: 'SEARCH_EVENTS_SUCCESS',
  SEARCH_EVENTS_FAIL: 'SEARCH_EVENTS_FAIL',

  //GET COORDINATES FOR MAP
  GET_COORDINATES: 'GET_COORDINATES',
  GET_COORDINATES_SUCCESS: 'GET_COORDINATES_SUCCESS',
  GET_COORDINATES_FAIL: 'GET_COORDINATES_FAIL',

  //GET EVENT DETAILS
  GET_EVENT_DETAILS: 'GET_EVENT_DETAILS',
  GET_EVENT_DETAILS_SUCCESS: 'GET_EVENT_DETAILS_SUCCESS',
  GET_EVENT_DETAILS_FAIL: 'GET_EVENT_DETAILS_FAIL'
};
