import moment from 'moment';
import React from "react";

const Options = (arrayUsers, arrayPaid, arrayQr, arrayDate) => {

  return {
    chart: {
      type: 'column',
      backgroundColor: 'rgba(255, 255, 255, 0)',
      maxHeight: 300,
      spacingLeft: 0,
      spacingRight: 0,
      style: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '12px'
      }
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      categories: arrayDate,
      type: 'category',
      gridLineColor: '#D7D7D7',
      gridLineDashStyle: 'ShortDash',
      labels: {
        enabled: true,
        style: {
          fontSize: '12px',
          color: '#A1A6B2'
        },
        formatter: function () {
          return moment(this.value).format('DD.MM');
        }
      },
      crosshair: true
    },
    yAxis: {
      gridLineColor: '#D7D7D7',
      gridLineDashStyle: 'ShortDash',
      title: {
        text: undefined
      },
      labels: {
        enabled: true,
        format: '{value}',
        style: {
          fontSize: '12px',
          color: '#A1A6B2'
        }
      },
      formatter: function () {
        return moment(this.value).format('DD.MM');
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0, "><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.12,
        borderWidth: 0
      }
    },

    series: [{
      name: 'Signups',
      data: arrayUsers,
      color:'#EC5B75'

    }, {
      name: 'Paid subscriptions',
      data: arrayPaid,
      color:'#FFD484'


    },
      //   {
      //   name: 'QR-code subscriptions',
      //   data: arrayQr,
      //   color:'#BE5BEC'
      // },
    ]
  };
};

export default Options;
