import React, {useEffect, useState} from 'react';
import {PieChart} from 'react-minimal-pie-chart';
import styles from './Total.module.scss';
import {useSelector, useDispatch} from 'react-redux';
import {getVenuesChart} from '../venuesActions';
const Total = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('active');

  useEffect(() => {
    dispatch(getVenuesChart());
  }, []);

  const {chartData} = useSelector((state) => state.venues);

  const data = [
    {title: 'Active', value: chartData && chartData.active && chartData.active, color: '#ec5b75'},
    {title: 'Deleted', value: chartData && chartData.deleted && chartData.deleted, color: '#d1d5d9'}
  ];

  const chartSum = data?.map((item) => item.value).reduce((prev, curr) => prev + curr, 0);

  return (
    <div className={styles.root}>
      <h3>Total</h3>
      <div className={styles.inner}>
        <div className={styles.chart_wrap}>
          <PieChart
            className={styles.chart}
            data={data}
            startAngle={-88}
            lineWidth={15}
            paddingAngle={(data[0].value === 0 || data[1].value) === 0 ? 0 : 5}
            animate
          />
          <div className={styles.chart_inner}>
            <b className={styles.chart_sum}>{chartSum}</b>
            <span className={styles.chart_label}>Venues</span>
          </div>
        </div>
        <ul className={styles.list}>
          {data?.map(({title, value, color}, index) => (
            <li key={index} className={styles.item}>
              <span style={{backgroundColor: `${color}`}} /> <b>{value}</b> {title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Total;
