import React, {useEffect, useState} from 'react';
import {TabItem, Tabs} from "../../../shared/Tabs";
import {Pagination} from "../../../shared";
import {getProfiles, getSubscriptionsQR, getSubscriptionsPaid} from '../../Users/userActions';
import {useParams} from 'react-router-dom';
import {Link} from "react-router-dom";
import {authPath, mainPath} from "../../../routes/paths";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {PropagateLoader} from "react-spinners";
import {ReactComponent as SortUpIcon} from "../../../assets/images/sort_up.svg";
import {ReactComponent as SortDownIcon} from "../../../assets/images/sort_down.svg";
import {ReactComponent as OK} from "../../../assets/icons/ok.svg";
import {ReactComponent as Men} from "../../../assets/images/gender_men.svg";
import {ReactComponent as Women} from "../../../assets/images/gender_women.svg";
import {ReactComponent as ArrowNext} from "../../../assets/icons/arrow_next.svg";
import {ReactComponent as Anonymous} from "../../../assets/images/Anonymous.svg";
import styles from "./UserDetails.module.scss";

const UserDetails = () => {
    const { profiles, profilesLoad, subscriptions_paid, subscriptions_paidLoad, subscriptions_qr, subscriptions_qrLoad } = useSelector(({ user }) => (user));
    const dispatch = useDispatch();
    const {id} = useParams();
    const now = moment();

    useEffect(() => {
        dispatch(getProfiles(id));
    }, []);

    useEffect(() => {
    }, [profiles, subscriptions_qr, subscriptions_paid]);


    const [parameters, setParameters] = useState({
        ordering: '',
        page_size: 10
    });

    useEffect(() => {
        setPage({ selected: 0 }, true);
    }, [parameters]);

    const [activePage, setActivePage] = useState(0);

    const setPage = ({ selected }, isReload) => {
        setActivePage(selected)
        if (isReload) doRequest(selected);
    };

    const doRequest = page => {
        let url = [`page=${page + 1}`];
        for (let key in parameters) {
            if (parameters[key] !== null && parameters[key] !== '') {
                url.push(`${key}=${parameters[key].value ? parameters[key].value : parameters[key]}`);
            }
        }
        dispatch(getSubscriptionsQR(id && `${id}`, url.join('&'))),
            dispatch(getSubscriptionsPaid(id && `${id}`, url.join('&')))
    };

    const changeTabIndex = () => {
        setParameters({ ...parameters, r: !parameters.r });
    };

    const sortListPaid = [
        {
            name: 'Location',
            value: null,
        },
        {
            name: 'Payment method',
            value: null,
        },
        {
            name: 'Date & time',
            value: 'buy_date',
        }
    ];
    const sortListQRCode = [
        {
            name: 'Venue',
            value: null,
        },
        {
            name: 'Event',
            value: null,
        },
        {
            name: 'Location',
            value: null,
        },
        {
            name: 'Date & time',
            value: 'buy_date',
        }
    ];

    return (
        <div className={`${styles.user_details} page-wrap`}>
            <div className={`${styles.back_info}`}>
                <Link className='' to={mainPath.users}>Users</Link>
                <ArrowNext/>
                <span>User details</span>
            </div>
            {profilesLoad ?
                <div className={`${styles.user}`}>
                    <div className='load'>
                        <PropagateLoader color={'#EC5B75'}/>
                    </div>
                </div>
                :
                <div className={`${styles.user}`}>
                    <div className={`${styles.avatar}`}>{profiles.picture === null ? <Anonymous/> : <img src={profiles.picture} alt="picture"/> }</div>
                    <div>
                        <div className={`${styles.name}`}>
                            {profiles.first_name === null ? 'Anonymous' : profiles.first_name}
                            {/*{user.status ? <OK/> : null}*/}
                        </div>
                        <div className={`${styles.info}`}>
                            {profiles.gender === 'male' ? <Men/>
                                : profiles.gender === 'female' ? <Women/>
                                    : ''
                            }

                            {profiles.city === null ? '' : <span>{profiles.city},</span> }
                            {profiles.birth_date === null ? '' :
                            <div>{moment(now).format('YYYY') - moment(profiles.birth_date).format('YYYY')}y.o.</div>
                            }
                            {profiles.city === null && profiles.gender === null && profiles.first_name === null && profiles.birth_date === null ? '' : ' | '}
                            member since {moment(profiles.registered).format('DD.MM.YYYY')}
                        </div>
                        <div className={`${styles.interests}`}>
                            {profiles.interest && profiles.interest.map(({id, name}) => (
                                <div key={id}>
                                    {name}
                                </div>
                            ))}
                        </div>
                        <div className={`${styles.desc}`}>{profiles.bio}</div>
                    </div>
                </div>
            }
            <div className={`${styles.subscriptions}`}>
                <h3>Subscriptions</h3>
                <Tabs defaultIndex='1' changeTabIndex={changeTabIndex}>
                    <TabItem label='Paid' index='1'>
                        {subscriptions_paidLoad ?
                            <div className='load'>
                                <PropagateLoader color={'#EC5B75'}/>
                            </div>
                            :
                            <div className='no_load'>
                                {subscriptions_paid.count > 0 ?
                                    <table className={`${styles.table_paid} table`}>
                                        <thead>
                                        <tr>
                                            {sortListPaid.map((el, idx) => (
                                                <th>
                                                    {el.value === null ? (
                                                        el.name
                                                    ) : (
                                                        <button
                                                            key={idx}
                                                            className={`good-hover sort_btn${el.value
                                                                ? el.value ===
                                                                (parameters.ordering.includes('-') ? parameters.ordering.slice(1) : parameters.ordering)
                                                                    ? el.value === parameters.ordering
                                                                        ? ' up'
                                                                        : ' down'
                                                                    : ''
                                                                : ' no-sort'
                                                            }`}
                                                            onClick={() =>
                                                                el.value &&
                                                                setParameters({
                                                                    ...parameters,
                                                                    ordering:
                                                                        el.value ===
                                                                        (parameters.ordering.includes('-') ? parameters.ordering.slice(1) : parameters.ordering)
                                                                            ? el.value === parameters.ordering
                                                                            ? `-${el.value}`
                                                                            : el.value
                                                                            : el.value
                                                                })
                                                            }
                                                        >
                                                            {el.name}
                                                            <div>
                                                                <SortUpIcon className='up_icon'/>
                                                                <SortDownIcon className='up_down'/>
                                                            </div>
                                                        </button>
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {subscriptions_paid && subscriptions_paid.results &&
                                        subscriptions_paid.results.map(({buy_date, id, type, location, address}, idx) => (
                                            <tr key={idx}>
                                                <td className='w-45'>{address ? <span>{address}</span> : '-'}</td>
                                                <td className='w-30'>{type}</td>
                                                <td className='w-25'>{moment(buy_date).format('DD.MM.YYYY')}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    :
                                    <div>No items</div>

                                }
                            </div>
                        }
                        {subscriptions_paid.count > 10 ?
                            <Pagination
                                active={activePage}
                                pageCount={Math.ceil(subscriptions_paid.count / parameters.page_size)}
                                onChange={(page) => setPage(page, true)}
                            />
                            : null
                        }
                    </TabItem>
                    <TabItem
                        // label='QR-code'
                        // index='2'
                    >
                        {/*{subscriptions_qrLoad ?*/}
                        {/*    <div className='load'>*/}
                        {/*        <PropagateLoader color={'#EC5B75'}/>*/}
                        {/*    </div>*/}
                        {/*    :*/}
                        {/*    <div className='no_load'>*/}
                        {/*        {subscriptions_qr.count > 0 ?*/}
                        {/*            <table className={`${styles.qr_code} table`}>*/}
                        {/*                <thead>*/}
                        {/*                <tr>*/}
                        {/*                    {sortListQRCode.map((el, idx) => (*/}
                        {/*                        <th>*/}
                        {/*                            {el.value === null ? (*/}
                        {/*                                el.name*/}
                        {/*                            ) : (*/}
                        {/*                                <button*/}
                        {/*                                    key={idx}*/}
                        {/*                                    className={`good-hover sort_btn${el.value*/}
                        {/*                                        ? el.value ===*/}
                        {/*                                        (parameters.ordering.includes('-') ? parameters.ordering.slice(1) : parameters.ordering)*/}
                        {/*                                            ? el.value === parameters.ordering*/}
                        {/*                                                ? ' up'*/}
                        {/*                                                : ' down'*/}
                        {/*                                            : ''*/}
                        {/*                                        : ' no-sort'*/}
                        {/*                                    }`}*/}
                        {/*                                    onClick={() =>*/}
                        {/*                                        el.value &&*/}
                        {/*                                        setParameters({*/}
                        {/*                                            ...parameters,*/}
                        {/*                                            ordering:*/}
                        {/*                                                el.value ===*/}
                        {/*                                                (parameters.ordering.includes('-') ? parameters.ordering.slice(1) : parameters.ordering)*/}
                        {/*                                                    ? el.value === parameters.ordering*/}
                        {/*                                                    ? `-${el.value}`*/}
                        {/*                                                    : el.value*/}
                        {/*                                                    : el.value*/}
                        {/*                                        })*/}
                        {/*                                    }*/}
                        {/*                                >*/}
                        {/*                                    {el.name}*/}
                        {/*                                    <div>*/}
                        {/*                                        <SortUpIcon className='up_icon'/>*/}
                        {/*                                        <SortDownIcon className='up_down'/>*/}
                        {/*                                    </div>*/}
                        {/*                                </button>*/}
                        {/*                            )}*/}
                        {/*                        </th>*/}
                        {/*                    ))}*/}
                        {/*                </tr>*/}
                        {/*                </thead>*/}
                        {/*                <tbody>*/}
                        {/*                {subscriptions_qr && subscriptions_qr.results &&*/}
                        {/*                subscriptions_qr.results.map(({buy_date, id, event, type, venue, location}, idx) => (*/}
                        {/*                    <tr key={idx}>*/}
                        {/*                        <td className='w-25'>{venue ? <span>{venue}</span> : '-'}</td>*/}
                        {/*                        <td className='w-25'>{event ? <span>{event}</span> : '-'}</td>*/}
                        {/*                        <td className='w-30'>{location ? <span>{location}</span> : '-'}</td>*/}
                        {/*                        <td className='w-20'>{moment(buy_date).format('DD.MM.YYYY')}</td>*/}
                        {/*                    </tr>*/}
                        {/*                ))}*/}
                        {/*                </tbody>*/}
                        {/*            </table>*/}
                        {/*            :*/}
                        {/*            <div>No items</div>*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*}*/}
                        {/*{subscriptions_qr.count > 10 ?*/}
                        {/*    <Pagination*/}
                        {/*        active={activePage}*/}
                        {/*        pageCount={Math.ceil(subscriptions_qr.count / parameters.page_size)}*/}
                        {/*        onChange={(page) => setPage(page, true)}*/}
                        {/*    />*/}
                        {/*    : null*/}
                        {/*}*/}
                    </TabItem>
                </Tabs>
            </div>
        </div>
    );
};

export default UserDetails;
