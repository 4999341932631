import React, {useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {authPath, mainPath} from '../../routes/paths';
import {ReactComponent as HeaderLogo} from '../../assets/icons/Logo.svg';
import {ReactComponent as LogoutIcon} from '../../assets/icons/logout.svg';
import './Header.scss';
import {useDispatch, useSelector} from "react-redux";
import {getAccounts} from "../../features/Auth/authActions";

const Header = () => {
  const history = useHistory();
  const {accounts} = useSelector(({ auth }) => (auth));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccounts());
  }, []);
  useEffect(() => {
  }, [accounts]);

  const logout = () => {
    localStorage.removeItem('token');
    history.push(authPath.signIn);
  };

  return (
    <header className='header'>
      <div className='header__container'>
        <Link className='good-hover' to={mainPath.users}>
          <HeaderLogo/>
        </Link>
        <div className='header__logout'>
          {accounts.email}
          <LogoutIcon className='pulse' onClick={logout}/>
        </div>
      </div>
    </header>
  );
};

export default Header;
