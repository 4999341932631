import {AUTH} from './authActionTypes';

export function postSignIn(data) {
  return {
    type: AUTH.SIGN_IN,
    payload: {
      client: 'default',
      request: {
        url: `/accounts/login/`,
        method: 'post',
        data,
      },
    },
  };
}

export function postPassRecovery(data) {
  return {
    type: AUTH.PASS_RECOVERY,
    payload: {
      client: 'default',
      request: {
        url: `/accounts/password-recovery/send-email/`,
        method: 'post',
        data,
      },
    },
  };
}

export function postPassConfirm(data) {
  return {
    type: AUTH.PASS_CONFIRM,
    payload: {
      client: 'default',
      request: {
        url: `/accounts/password-recovery/`,
        method: 'post',
        data,
      },
    },
  };
}

export function getAccounts() {
  return {
    type: AUTH.GET_ACCOUNTS,
    payload: {
      request: {
        url: `/accounts/me/`,
        method: 'GET'
      }
    }
  };
}
