import {VENUES} from './venuesActionTypes';

export function getVenuesChart() {
  return {
    type: VENUES.GET_VENUES,
    payload: {
      request: {
        url: `/creator/venues/stats/`,
        method: 'GET'
      }
    }
  };
}

export function getVenuesList(querry) {
  return {
    type: VENUES.GET_VENUES_LIST,
    payload: {
      request: {
        url: `/creator/venues/stats/${querry}`,
        method: 'GET'
      }
    }
  };
}

export function searchVenues(status, page, search, ordering) {
  return {
    type: VENUES.SEARCH_VENUES,
    payload: {
      request: {
        url: `/creator/venues/?status=${status}${page ? `&page=${page}` : ''}${search ? `&search=${search}` : ''}${
          ordering ? `&ordering=${ordering}` : ''
        }`,
        method: 'GET'
      }
    }
  };
}

export function getCoordinates() {
  return {
    type: VENUES.GET_COORDINATES,
    payload: {
      request: {
        url: `/creator/venues/map/`,
        method: 'GET'
      }
    }
  };
}

export function getSingleVenue(id) {
  return {
    type: VENUES.GET_SINGLE_VENUE,
    payload: {
      request: {
        url: `/creator/venues/${id}/`,
        method: 'GET'
      }
    }
  };
}
