import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import Options from './chartOptions';
import {PropagateLoader} from 'react-spinners';

import styles from "./Activity.module.scss";
import {useSelector} from "react-redux";

const Activity = () => {
    const { statsLoad, arrayUsers, arrayPaid, arrayQr, arrayDate, statsChart } = useSelector(({ user }) => (user));
    return (
        <div className={`${styles.activity}`}>
            <h3 className='mb-24'>Activity</h3>
            <div className={`${styles.chart_wrapper}`}>
                {statsLoad ? (
                    <div className='load'>
                        <PropagateLoader color={'#EC5B75'} />
                    </div>
                ) : (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={Options(arrayUsers, arrayPaid, arrayQr, arrayDate)}
                        className={`${styles.highchart}`}
                    />
                )}
            </div>
        </div>
    );
};

export default Activity;
