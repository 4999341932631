import {VENUES} from './venuesActionTypes';

const INITIAL_STATE = {
  loading: false,
  chartData: null,
  list: [],
  coordinates: [],
  singleVenue: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case VENUES.GET_VENUES:
    case VENUES.GET_VENUES_LIST:
    case VENUES.SEARCH_VENUES:
    case VENUES.GET_COORDINATES:
    case VENUES.GET_SINGLE_VENUE:
      return {
        ...state,
        loading: true
      };

    case VENUES.GET_VENUES_FAIL:
    case VENUES.GET_VENUES_LIST_FAIL:
    case VENUES.SEARCH_VENUES_FAIL:
    case VENUES.GET_COORDINATES_FAIL:
    case VENUES.GET_SINGLE_VENUE_FAIL:
      return {
        ...state,
        loading: false
      };

    case VENUES.GET_VENUES_SUCCESS:
      return {
        ...state,
        chartData: action.payload.data,
        loading: false
      };

    case VENUES.GET_VENUES_LIST_SUCCESS:
    case VENUES.SEARCH_VENUES_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        loading: false
      };

    case VENUES.GET_COORDINATES_SUCCESS:
      return {
        ...state,
        coordinates: action.payload.data,
        loading: false
      };

    case VENUES.GET_SINGLE_VENUE_SUCCESS:
      return {
        ...state,
        singleVenue: action.payload.data,
        loading: false
      };

    default:
      return state;
  }
}
